enum Direction {
  LEFT = 'left',
  RIGHT = 'right',
}

const SCROLL_BEHAVIOUR = 'smooth';

export {
  Direction,
  SCROLL_BEHAVIOUR,
};
